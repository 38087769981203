


.layout-grid {
    display: grid;
}

.MuiBackdrop-root{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: -1;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 10%) !important;
    -webkit-tap-highlight-color: transparent;
}

.MuiDrawer-paperAnchorDockedLeft {
    border-right: 1px solid rgba(255, 255, 255, 0.12);
    justify-content: center;
}